versuriNoiApp = angular.module('versuriNoiApp', [
  'ngRoute',
  'ngAnimate',
  'ngResource',
  'ngStorage',
  'ngSanitize',
  'appRoutes',
  'directives',
  
  'ngFileUpload',
  'ui.select',
  'offClick',
  'infinite-scroll',
  'angular-jqcloud',
  'angular-loading-bar'
  
]);

//loading bar config
versuriNoiApp.config(['cfpLoadingBarProvider', function(cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = false;
    cfpLoadingBarProvider.latencyThreshold = 200;
}]);

versuriNoiApp.config(function(uiSelectConfig) {
    uiSelectConfig.theme = 'bootstrap';
});

//page title and if a request returns 404 then we redirect the user to 404
versuriNoiApp.run(['$location', '$rootScope', '$window', function($location, $rootScope, $window) {
    $rootScope.$on('$routeChangeSuccess', function (event, current, previous) {
        $rootScope.title = '';
        $rootScope.metaDescription = null;
        
        if($location.path() === '/')
        {
            $rootScope.title = 'Versuri, versuri noi - Versurinoi.ro';
            $rootScope.metaDescription = 'Versuri - Versuri pentru melodiile tale preferate. Versurinoi.ro';
        }
        if($location.path() === '/users/login')
            $rootScope.title = 'Login';
        else if($location.path() === '/users/cont-nou')
            $rootScope.title = 'Cont nou';
        else if($location.path() === '/users/am-uitat-parola')
            $rootScope.title = 'Am uitat parola';
        else if($location.path() === '/cont')
            $rootScope.title = 'Contul meu';
        else if($location.path() === '/adauga/versuri')
            $rootScope.title = 'Adauga versuri';
        else if($location.path() === '/adauga/artist')
            $rootScope.title = 'Adauga artist';
        else if($location.path() === '/contact')
            $rootScope.title = 'Contact';

        //google analytics
        $window.ga('send', 'pageview', { page: $location.url() });
    });
    $rootScope.$on("$routeChangeError", function (event, current, previous, error) {
        if(error.status === 404 || error.status === 500) {
           $location.path('/404');
        }
        if(error.status === 401 || error.status === 400) {
           $location.path('/users/login');
        }
        if($location.path() === '/cont') {
            $location.path('/users/login');
        }
        $location.path('/404');
    });
}]);