versuriNoiApp.factory('LyricList', ['$resource',
    function ($resource) {
        return $resource('/api/lists/:listId', {
          listId: '@listId'
        }, 
        {
            edit: {
                method: 'POST',
                url: '/api/lists/edit',
                ignoreLoadingBar: true
            },
            addLyricToList: {
                method: 'POST',
                url: '/api/lists/addLyricToList',
                ignoreLoadingBar: true
            },
            getListLyrics: {
                method: 'GET',
                url: '/api/lists/getListLyrics',
                ignoreLoadingBar: true
            }
        });
    }
]);