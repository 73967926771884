versuriNoiApp.factory('Comments', function($http) {
    var Comments = function(lyric_id) {
        this.items = [];
        this.busy = false;
        this.disabled = false;
        this.after = '';
        this.lyric_id = lyric_id;
    };

    Comments.prototype.nextPage = function() {
        if (this.busy) return;
        this.busy = true;
  
        $http.post('/api/comments/getComments', {after: this.after, lyric_id: this.lyric_id}, {ignoreLoadingBar: true})
            .success(function(data) {
                for (var i = 0; i < data.length; i++) {
                  this.items.push(data[i]);
                }
                this.after = this.items.length;//this.items[this.items.length - 1].id;
                this.busy = false;
                
                if(data.length === 0)
                {
                    this.disabled = true;
                }
            }.bind(this));
    };

    return Comments;
});

versuriNoiApp.factory('Comment', function ($http) {
    return {
        addComment: function(lyric_id, body) {
            return $http.post('/api/comments/addComment', {lyric_id: lyric_id, body: body});
        }
    }
});