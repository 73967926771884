versuriNoiApp.controller('MainController', ['$scope', '$location', '$localStorage', '$route', 'User', 
  function ($scope, $location, $localStorage, $route, User) {
 
    /**
     * Generating an array with the alphabet letters
     *
     * @param route
     * @returns {boolean}
     */
    $scope.letters = [
        {
            key: '0-9',
            value: '#'
        }
    ];

    //current date
    $scope.date = new Date();
    
    //array containing the alphabet letters
    var a = 65;
    for (var i = 0; i<26; i++)
        $scope.letters.push({key: String.fromCharCode(a + i), value: String.fromCharCode(a + i)});
    
    /**
     * Responsible for highlighting the currently active menu item in the navbar.
     *
     * @param route
     * @returns {boolean}
     */
    $scope.isActive = function (route) {
        return route === $location.path();
    };

    /**
     * Query the authenticated user by the Authorization token from the header.
     *
     * @param user {object} If provided, it won't query from database, but take this one.
     * @returns {null}
     */
    $scope.getAuthenticatedUser = function (user) {     
        if (user) {
            $scope.authenticatedUser = user;
            return;
        }

        if (typeof $localStorage.token === 'undefined') {
            return null;
        }

        new User().$getByToken().then(function (user) {
            $scope.authenticatedUser = user;
        }, function (err) {
            delete $localStorage.token;
            $route.reload();
        });
    };

    $scope.logout = function () {
        delete $localStorage.token;
        $scope.authenticatedUser = null;
        $location.path('/');
    };

  }
]);