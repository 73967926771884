versuriNoiApp.controller('CommentController', ['$scope', 'Comments',
    function ($scope, Comments) {
        $scope.comment = new Comments($scope.lyricDetails.id);
    }
]);

versuriNoiApp.controller('NewCommentController', ['$scope', '$location', 'Comment',
    function ($scope, $location, Comment) {
        $scope.checkAuth = function() {
            if ($scope.authenticatedUser)
            {
                $scope.showPosteaza = true;
            }
            else
            {
                $location.path('/users/login');
            }
        }
        
        $scope.addComment = function() {
            newCommentBody = this.newComment;
            Comment.addComment($scope.lyricDetails.id, newCommentBody).success(function(data) {
                $scope.$parent.lyricDetails.number_of_comments += 1;
                $scope.$parent.comment.items.splice(0, 0, data.newComment);
                $scope.newComment = "";
            }).error(function(data) {
                alert(data.error);
            });
        };
    }
]);