angular.module('appRoutes', []).config(['$routeProvider', '$locationProvider', '$httpProvider',
  function ($routeProvider, $locationProvider, $httpProvider) {

    $routeProvider
      .when('/', {
        templateUrl: '/partials/index',
        controller: 'IndexController',
        resolve: {
            topLyrics: ['Lyric', function(Lyric) {
                return Lyric.getTopLyrics().$promise;
            }],
            topArtists: ['Artist', function(Lyric) {
                return Lyric.getTopArtists().$promise;
            }],
            latestLyrics: ['Lyric', function(Lyric) {
                return Lyric.getLatestLyrics().$promise;
            }]
        }
      })
      .when('/artisti/:letter', {
        templateUrl: '/partials/artists/index',
        controller: 'ArtistByLetterController',
        resolve: {
            artistsByFirstLetter: ['Artist', '$route', function(Artist, $route) {
                return Artist.getArtistsByFirstLetter($route.current.params).$promise;
            }]
        }
      })
      .when('/versuri/:artist', {
        templateUrl: '/partials/artists/view',
        controller: 'ArtistController',
        resolve: {
            artistInfo: ['Artist', '$route', function(Artist, $route) {
                return Artist.getArtistInfo($route.current.params).$promise;
            }],
            artistLyrics: ['Artist', '$route', function(Artist, $route) {
                return Artist.getLyricsByArtist($route.current.params).$promise;
            }]
        }
      })
      .when('/versuri-:lyricSlug', {
        templateUrl: '/partials/lyrics/view',
        controller: 'LyricDetailsController',
        resolve: {
            lyricDetails: ['Lyric', '$route', function(Lyric, $route) {
                return Lyric.show($route.current.params).$promise;
            }]
        }
      })
      .when('/cautare/:search', {
        templateUrl: '/partials/search/view',
        controller: 'SearchResultsController',
        resolve: {
            searchResults: ['Search', '$route', function(Search, $route) {
                return Search.search($route.current.params).$promise;
            }]
        }
      })
      .when('/user/:id', {
        templateUrl: '/partials/users/view',
        controller: 'UserDetailsController',
        resolve: {
            userDetails: ['User', '$route', function(User, $route) {
                return User.get($route.current.params).$promise;
            }]
        }
      })
      .when('/cont', {
        templateUrl: '/partials/users/cont',
        controller: 'UserAccountController',
        resolve: {
            userDetails: ['User', '$route', function(User) {
                return User.get().$promise;
            }]
        }
      })
      .when('/list/:id', {
        templateUrl: '/partials/users/list',
        controller: 'LyricListDetailsController',
        resolve: {
            listDetails: ['LyricList', '$route', function(LyricList, $route) {
                return LyricList.getListLyrics($route.current.params).$promise;
            }]
        }
      })
      .when('/contact', {
        templateUrl: '/partials/contact',
        controller: 'ContactController'
      })
      .when('/404', {
        templateUrl: '/partials/404'
      })
      .when('/login/:token', {
        templateUrl: '/partials/users/social',
        controller: 'SocialController',
        resolve: {
            tokenString: ['$route', function($route) {
                return $route.current.params.token;
            }]
        }
      })
      .when('/:slug', {
        templateUrl: '/partials/texts/view',
        controller: 'TextController',
        resolve: {
            textDetails: ['SiteText', '$route', function(SiteText, $route) {
                return SiteText.getText($route.current.params).success(function(data){
                    return data;
                });
            }]
        }
      })
      .when('/:category/:action?/:id?', {
        templateUrl: function (params) {
          var allowedParams = ['category', 'action', 'id'];
          var paramVals = [];
          for (var key in params) {
            if (allowedParams.indexOf(key) !== -1) {
              paramVals.push(params[key]);
            }
          }
          return '/partials/' + paramVals.join('/');
        }
      })
      .otherwise({
        redirectTo: '/'
      });
    
    $locationProvider.html5Mode(true);

    $httpProvider.interceptors.push(['$q', '$localStorage', '$location',
      function ($q, $localStorage, $location) {
        return {
          request: function (config) {
            config.headers = config.headers || {};
            if ($localStorage.token) {
              config.headers.Authorization = 'Bearer ' + $localStorage.token;
            }
            return config;
          },
          response: function (res) {
            if (res.status === 401) {
                $location.path('users/login');
            }
            return res || $q.when(res);
          }
        };
      }
    ]);

  }
]);