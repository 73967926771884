versuriNoiApp.controller('LyricController', ['$scope', 'Lyric',
    function ($scope, Lyric) {
        /*$scope.getLatestUserCreations = function () {   
            $scope.latestUserCreations = Lyric.getLatestUserCreations();
        };
        $scope.getMostCommentedUserCreations = function () {   
            $scope.mostCommentedUserCreations = Lyric.getMostCommentedUserCreations();
        };*/
    }
]);

versuriNoiApp.controller('LyricDetailsController', ['$rootScope', '$scope', '$sce', 'lyricDetails', 'Artist',
    function ($rootScope, $scope, $sce, lyricDetails, Artist) {
        $scope.lyricDetails = lyricDetails;
        $scope.lyricDetails.formatted_body = $sce.trustAsHtml(lyricDetails.formatted_body);
        $scope.lyricDetails.embed_youtube_link = $sce.trustAsResourceUrl(lyricDetails.embed_youtube_link);
        $scope.colors = ["rgb(31, 119, 180)", "rgb(255, 127, 14)", "rgb(44, 160, 44)", "rgb(214, 39, 40)", "rgb(255, 187, 120)", "rgb(152, 223, 138)", "rgb(203, 203, 203)"];

        //page title
        var mainArtist = null;
        for (var i = 0; i < lyricDetails.artists.length; i++) {
            if (lyricDetails.artists[i].main == 1) {
                mainArtist = lyricDetails.artists[i];
            }
        }
   
        $scope.mainArtist = mainArtist; 
         
        $scope.getCoverPhotoStyle = function () {
            if (mainArtist.cover_image_url !== null) {
                $scope.coverImg = 'background-image: url(' + mainArtist.cover_image_url + ')';
            }
        };
        
        $scope.getOtherArtistLyrics = function () {   
            $scope.otherArtistLyrics = Artist.getOtherArtistLyrics({artist_id: mainArtist.id, lyric_id: lyricDetails.id});
        };
        
        $scope.print = function() {
            var printContents = '';
            $.each( $('.printable'), function( ) {
                printContents += $(this).html(); 
            });
            printContents += '<br><br>www.versurinoi.ro'; 
             
            var popupWin = window.open('', '_blank', 'width=900,height=800');
            popupWin.document.open()
            popupWin.document.write('<html><head><link rel="stylesheet" href="/css/print.css"></head><body onload="window.print()">' + printContents + '</html>');
            popupWin.document.close();
        } 
        
        $scope.download = function() {
            var printContents = lyricDetails.complete_title + '\n\n' + lyricDetails.body + '\n\nwww.versurinoi.ro'; 
             
            saveTextAs(printContents, lyricDetails.complete_title + ".txt");
        } 

        $scope.share = function (network) {
            var url = 'http://www.versurinoi.ro/versuri-' + lyricDetails.slug;
            if(network == 'facebook')
            {
                var picture = 'http://www.versurinoi.ro/img/sigla.png';
                if (mainArtist.hasOwnProperty('image_url') && mainArtist.image_url) {
                    picture = 'http://www.versurinoi.ro/' + mainArtist.image_url;
                }

                FB.ui(
                {
                    method: 'feed',
                    name: 'Versuri ' + lyricDetails.complete_title,
                    link: url,
                    picture: picture,
                    caption: '',
                    description: lyricDetails.body.substring(0,200) + '...',
                    message: ''
                });
                //window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, '_blank', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            }
            else if(network == 'twitter')
            {
                window.open('https://twitter.com/intent/tweet?counturl=' + url + '&amp;text=' + escape(lyricDetails.complete_title) + '&amp;url=' + url + '&amp;via=versurinoi_ro', '_blank', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            }
            else if(network == 'google')
            {
                window.open('https://plus.google.com/share?url=' + url, '_blank', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            }
        }

        $rootScope.title = 'Versuri ' + lyricDetails.complete_title;
        $rootScope.metaDescription = 'Versuri pentru melodia ' + lyricDetails.complete_title;
    }
]);

versuriNoiApp.controller('LyricAddController', ['$scope', '$http', '$sce', 'Lyric', 'Artist',
    function ($scope, $http, $sce, Lyric, Artist) {
      
        $scope.newLyric = {title : null, artist : [], feat : [], youtube: null, youtube_id: null, lyrics: null};

        $scope.artists = [];
        $scope.searchArtists = function(name) {
            $scope.artists = Artist.searchArtists(name);
        };
        
        $scope.featArtists = [];
        $scope.searchFeatArtists = function(name) {
            $scope.featArtists = Artist.searchArtists(name, $scope.mainArtist);
        };
        
        $scope.selectArtist = function(item) {
            $scope.showFeat = true;
            
            $scope.mainArtist = item;
            
            $scope.newLyric.feat = [];
            $scope.newLyric.youtube_id = null;
 
            $http.post('/api/lyrics/checkIfLyricExists', {title: $scope.newLyric.title, artist: $scope.newLyric.artist}, {ignoreLoadingBar: true}).
                then(function(res){
                    if(res.data)
                    {
                        $scope.newLyric.artist = null;
                        
                        $scope.newLyricsForm.$invalid = true;
                        $scope.newLyricsForm.title.$invalid = true;
                        
                        $scope.lyricExists = true;
                    }
                    else
                    {
                        $scope.lyricExists = false;
                        
                        Lyric.getYoutubeVideo($scope.newLyric).$promise.then(function(resource) {
                            $scope.youtube_link = $sce.trustAsResourceUrl(resource.url);
                            $scope.newLyric.youtube_id = resource.id;
                        });
                    }   
                });  
        }
        
        $scope.create = function() {
            if ($scope.newLyricsForm.$valid) {
                var lyric = new Lyric({
                    title: this.newLyric.title,
                    artist: this.newLyric.artist,
                    feat: this.newLyric.feat,
                    lyrics: this.newLyric.lyrics,
                    youtube_id: this.newLyric.youtube_id
                });

                lyric.$save(function (response) {
                    $scope.success = response.success;
                    
                    $scope.newLyric = {title : null, artist : [], feat : [], youtube: null, youtube_id: null, lyrics: null};
                    $scope.youtube_link = null;

                    $scope.newLyricsForm.$setPristine();
                    $scope.newLyricsForm.$setUntouched();
                }, function (err) {
                    $scope.error = err.error;
                });
            }
        }
        
        $scope.clearYoutube = function() {
            $scope.youtube_link = null;
            $scope.newLyric.youtube = null;
            $scope.newLyric.youtube_id = null;
        }
    }
]);