versuriNoiApp.factory('Artist', ['$resource',
    function ($resource) {
        return $resource('/api/artists/:artistId', {
          artistId: '@artistId'
        }, 
        {
            getTopArtists: {
                method: 'GET',
                cache: true,
                isArray: true,
                url: '/api/artists/getTopArtists'
            },
            getArtistsByFirstLetter: {
                method: 'GET',
                isArray: true,
                url: '/api/artists/getArtistsByFirstLetter'
            },
            getLyricsByArtist: {
                method: 'GET',
                isArray: true,
                url: '/api/artists/getLyricsByArtist'
            },
            getArtistInfo: {
                method: 'GET',
                url: '/api/artists/getArtistInfo'
            },
            getOtherArtistLyrics: {
                method: 'GET',
                isArray: true,
                url: '/api/artists/getOtherArtistLyrics/:artist_id/:lyric_id',
                params: {
                  artist_id: '@artist_id',
                  lyric_id: '@lyric_id'
                }
            },
            searchArtists: {
                method: 'GET',
                isArray: true,
                url: '/api/artists/searchArtists'
            }
        });
    }
]);