versuriNoiApp.controller('ContactController', ['$scope', '$http',
    function ($scope, $http) {
        $scope.submit = function () {
            $http.post('/api/contact', {contact: this.contact}).
                then(function(response) {
                    $scope.success = response.data.success;
                    
                    $scope.contact = null;
                    $scope.contactForm.$setPristine();
                    $scope.contactForm.$setUntouched();
                }, function(response) {
                    $scope.error = response.data.error;
                }); 
        }
    }
]);