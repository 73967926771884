appDirective = angular.module('directives', []);

// Reacts upon enter key press.
appDirective.directive('enterStroke',
  function () {
    return function ($scope, $element, $attrs) {
      element.bind('keydown keypress', function (event) {
        if (event.which === 13) {
          $scope.$apply(function () {
            $scope.$eval($attrs.enterStroke);
          });
          event.preventDefault();
        }
      });
    };
  }
);

appDirective.directive('artistsCarousel', function () {
    return {
        restrict: 'A', //E
        scope: true,
        controller: function() {
            angular.element(document).ready(function ()
            {
                $("#artists-carousel").owlCarousel();
            });
        }
    }
});

appDirective.directive('sideComments', function ($http, $localStorage, $location) {
    return {
        restrict: 'A', //E
        scope: true,
        controller: function($scope) {
            angular.element(document).ready(function ()
            {
                // first require it.
                var SideComments = require('side-comments');
                
                var currentUser = null;
                var existingComments = null;

                if ($scope.lyricDetails.side_comments.length > 0) 
                {
                    existingComments = $scope.lyricDetails.side_comments;
                }

                // then, create a new SideComments instance, passing in the wrapper element and the optional the current user and any existing comments.
                sideComments = new SideComments('#commentable-area', currentUser, existingComments);
                
                // add the current user only if he is logged in and authentificated
                if (typeof $localStorage.token !== 'undefined') {
                    $scope.$watch('authenticatedUser', function() {
                        if($scope.authenticatedUser)
                        {
                            currentUser = {
                                id: $scope.authenticatedUser.id,
                                avatarUrl: $scope.authenticatedUser.avatar,
                                name: $scope.authenticatedUser.username
                            };

                            sideComments.setCurrentUser(currentUser);
                        }
                    });
                }
                
                // insert comment
                sideComments.on('commentPosted', function( comment ) {
                    return $http.post('/api/comments/postSideComment', {comment: comment, lyric_id: $scope.lyricDetails.id}).
                        then(function(res) {
                            if(res.status === 200)
                            {
                                sideComments.insertComment(comment);
                            }
                        }, function(res) {
                            alert(res.error);
                        });        
                });

                // delete comment
                sideComments.on('commentDeleted', function( comment ) {

                    return $http.post('/api/comments/deleteSideComment', {comment: comment}).
                        then(function(res) {
                            if(res.status === 200)
                            {
                                sideComments.removeComment(comment.sectionId, comment.id);
                            }
                        }, function(res) {
                            alert(res.error);
                        }); 
                });
                
                //marker click
                $(".marker").on("click", function() {
                    if (typeof $localStorage.token === 'undefined') {
                        $location.path('/users/login');
                    }
                });
            });
        }
    }
});

appDirective.directive('comparewith', ['$parse', function($parse){
  return {
     require:'ngModel',
      link:function(scope, elm, attr, ngModel){
        //Can use $parse or also directly comparing with scope.$eval(attr.comparewith) will work as well
        var getter = $parse(attr.comparewith);

        ngModel.$validators.comparewith = function(val){
          return val === getter(scope);
        }
        scope.$watch(attr.comparewith, function(v, ov){
          if(v !== ov){
             ngModel.$validate();
          }
        });
      }
  }
}]);

appDirective.directive('emailAvailable', function($http, $q) {
    return {
        restrict: 'AE',
        require: 'ngModel',
        link: function(scope, elm, attr, model) { 
            model.$asyncValidators.emailExists = function(modelValue, viewValue) {
                var value = modelValue || viewValue;

                // Lookup user by email
                return $http.post('/api/user/checkIfEmailExists', {email: value}, {ignoreLoadingBar: true}).
                    then(function(res){
                        if(res.data)
                        {
                            return $q.reject('exists');
                        }
                        else
                        {
                            return true;
                        }   
                    });
            }
        }
    }
});

appDirective.directive('emailAvailableLogged', function($http, $q) {
    return {
        restrict: 'AE',
        require: 'ngModel',
        link: function(scope, elm, attr, model) { 
            model.$asyncValidators.emailExists = function(modelValue, viewValue) {
                var value = modelValue || viewValue;

                // Lookup user by email
                return $http.post('/api/user/checkIfEmailExistsForLoggedUser', {email: value}, {ignoreLoadingBar: true}).
                    then(function(res){
                        if(res.data)
                        {
                            return $q.reject('exists');
                        }
                        else
                        {
                            return true;
                        }   
                    });
            }
        }
    }
});

appDirective.directive('checkDuplicateLyrics', function($http, $q, $sce, Lyric) {
    return {
        restrict: 'AE',
        require: 'ngModel',
        scope: false,
        link: function($scope, elm, attr, model) {
            model.$asyncValidators.lyricExists = function(modelValue, viewValue) {
                var value = modelValue || viewValue;

                $scope.newLyric.youtube_id = null;

                return $http.post('/api/lyrics/checkIfLyricExists', {title: value, artist: $scope.newLyric.artist}, {ignoreLoadingBar: true}).
                    then(function(res){
                        if(res.data)
                        {
                            return $q.reject('exists');
                        }
                        else
                        {
                            $scope.lyricExists = false;
                            $scope.newLyric.title = value;

                            Lyric.getYoutubeVideo($scope.newLyric).$promise.then(function(resource) {
                                $scope.youtube_link = $sce.trustAsResourceUrl(resource.url);
                                $scope.newLyric.youtube_id = resource.id;
                            });

                            return true;
                        }   
                    });
            }
        }
    }
});

appDirective.directive('checkYoutubeUrl', function($sce, Lyric) {
    return {
        restrict: 'AE',
        require: 'ngModel',
        scope: false,
        link: function($scope, elm, attr, model) { 
            model.$asyncValidators.invalidYoutubeUrl = function(modelValue, viewValue) {
                var value = modelValue || viewValue;

                return Lyric.checkYoutubeUrl({url: value}).$promise.then(function(resource) {
                    if(resource.url)
                    {
                        $scope.youtube_link = $sce.trustAsResourceUrl(resource.url);
                        $scope.newLyric.youtube_id = resource.id;
                        return true;
                    }
                    else
                    {
                        $scope.youtube_link = null;
                        $scope.newLyric.youtube_id = null;
                        return true;
                    }
                });
            }
        }
    }
});

appDirective.directive('checkDuplicateArtist', function($http, $q) {
    return {
        restrict: 'AE',
        require: 'ngModel',
        link: function(scope, elm, attr, model) { 
            model.$asyncValidators.artistExists = function(modelValue, viewValue) {
                var value = modelValue || viewValue;

                // Lookup artist by name
                return $http.post('/api/artists/checkIfArtistExists', {name: value}, {ignoreLoadingBar: true}).
                    then(function(res){
                        if(res.data)
                        {
                            return $q.reject('exists');
                        }
                        else
                        {
                            return true;
                        }   
                    });
            }
        }
    }
});

appDirective.directive('accountSidebar', function(LyricList) {
    return {
        restrict: 'E',
        scope: false,
        templateUrl: '/partials/users/account-sidebar',
        link: function($scope, elm, attr, model) { 
            LyricList.query().$promise.
                then(function(response) {
                    if(response)
                    {
                        $scope.lists = response;
                    }
                });
        }
    }
});

appDirective.directive('checkGuest', function($location) {
    return {
        restrict: 'AE',
        scope: false,
        link: function($scope, elm, attr, model) { 
            $scope.$watch('authenticatedUser', function (newval, oldval) {
                if(newval !== oldval)
                {
                    $location.path('/cont');
                }
            });
        }
    }
});

appDirective.directive('checkAuth', function($location, $localStorage) {
    return {
        restrict: 'AE',
        scope: false,
        link: function($scope, elm, attr, model) { 
            if( ! $localStorage.token)
            {
                $location.path('/users/login');
            }
        }
    }
});

appDirective.directive('fbPage', function() {
    function createHTML(href) {
        return '<div class="fb-page" data-href="' + href + '" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" data-show-posts="false"></div>';
    }
    return {
        restrict: 'A',
        scope: {},
        link: function($scope, $element, $attrs) {
            $attrs.$observe('pageHref', function (href) {
                $element.html(createHTML(href));
                //FB.XFBML.parse($element[0]);
            });
        }
    }
});

appDirective.directive('defaultCover', function($http) {
    return {
        restrict: 'C',
        scope: true,
        link: function(scope, elm, attr, model) {
            if (scope.coverImg == null) {
                return $http.get('/api/getDefaultCover', {}, {ignoreLoadingBar: true}).then(function (res) {
                    if (res.data) {
                        return scope.coverImg = 'background-image: url(' + res.data + ')';
                    }
                });
            }
        }
    }
});

appDirective.directive('profitsharePage', function() {
    function createHTML(href, height, margin) {
        return '<iframe src="' + href + '" frameborder="0" scrolling="no" style="overflow:hidden;width:100%;height:' + height + ';margin:' + margin + ';"></iframe>';
    }
    return {
        restrict: 'A',
        scope: {},
        link: function($scope, $element, $attrs) {
            $element.html(createHTML($attrs.pageHref, $attrs.pageHeight, $attrs.pageMargin));
        }
    }
});

appDirective.directive('pushMeHeart', function($http) {
    var clickTimeout;

    return {
        restrict: 'C',
        scope: true,
        link: function(scope, elm, attr, model) {
            elm.on('click', function() {
                var b = Math.floor((Math.random() * 100) + 1);
                var d = ["flowOne", "flowTwo", "flowThree"];
                var a = ["colOne", "colTwo", "colThree", "colFour", "colFive", "colSix"];
                var c = (Math.random() * (1.6 - 1.2) + 1.2).toFixed(1);
                $('<div class="heart part-' + b + " " + a[Math.floor((Math.random() * 6))] + '" style="font-size:' + Math.floor(Math.random() * (50 - 22) + 22) + 'px;"><i class="fa fa-heart"></i></div>').appendTo(".hearts").css({
                    animation: "" + d[Math.floor((Math.random() * 3))] + " " + c + "s linear"
                });
                $(".part-" + b).show();
                setTimeout(function () {
                    $(".part-" + b).remove()
                }, c * 900);

                scope.heartsCount = scope.heartsCount + 1;

                clearTimeout(clickTimeout);
                clickTimeout = setTimeout(function(){
                    $http.post('/api/hearts', {lyric_id: scope.lyricDetails.id, nr: scope.heartsCount}, {ignoreLoadingBar: true}).
                        then(function(res){
                            scope.heartsCount = 0;
                        });
                }, 500);
            });
        }
    }
});

appDirective.directive('autoFocus', function($timeout) {
    return {
        link: function (scope, element, attrs) {
            attrs.$observe("autoFocus", function(newValue){
                if (newValue === "true")
                    $timeout(function(){element[0].focus()});
            });
        }
    };
});