versuriNoiApp.controller('ArtistController', ['$rootScope', '$scope', '$routeParams', 'artistInfo', 'artistLyrics',
    function ($rootScope, $scope, $routeParams, artistInfo, artistLyrics) {
        $scope.artist = artistInfo;
        
        $scope.artistLyrics = artistLyrics;

        $scope.params = $routeParams;

        $scope.getCoverPhotoStyle = function () {
            if (artistInfo.cover_image_url !== null) {
                $scope.coverImg = 'background-image: url(' + artistInfo.cover_image_url + ')';
            }
        };

        //page title
        $rootScope.title = 'Versuri ' + artistInfo.name;
    }
]);

versuriNoiApp.controller('ArtistByLetterController', ['$rootScope', '$scope', '$routeParams', 'artistsByFirstLetter',
    function ($rootScope, $scope, $routeParams, artistsByFirstLetter) {

        $scope.artistsByFirstLetter = artistsByFirstLetter;

        $scope.params = $routeParams;
        
        //page title
        $rootScope.title = 'Artisti litera ' + $routeParams.letter;
        $rootScope.metaDescription = 'Artisti care incep cu litera ' + $routeParams.letter;
    }
]);

versuriNoiApp.controller('ArtistAddController', ['$scope', 'Artist', 'Upload',
    function ($scope, Artist, Upload) {

        $scope.newArtist = {name : null, bio : null, image : null, cover: null};
        
        $scope.create = function() {
            if ($scope.newArtistForm.$valid) {
                var artist = new Artist({
                    name: this.newArtist.name,
                    bio: this.newArtist.bio,
                    image: this.newArtist.image,
                    cover: this.newArtist.cover
                });

                artist.$save(function (response) {                    
                    $scope.success = response.success;

                    $scope.newArtist = null;
                    $scope.image = null;
                    $scope.cover = null;
                    $scope.coverImg = null;

                    $scope.newArtistForm.$setPristine();
                    $scope.newArtistForm.$setUntouched();
                }, function (err) {
                    $scope.error = err.error;
                });
            }
        }
        
        $scope.uploadImage = function (files) {
            if (files && files.length) {
                var file = files[0];
                Upload.upload({
                    url: 'api/user/uploadArtistImage',
                    fields: {},
                    headers: {'Content-Type': file.type},
                    method: 'POST',
                    file: file
                }).success(function (data, status, headers, config) {
                    $scope.newArtist.image = data.filename;
                }).error(function (data, status, headers, config) {
                    $scope.newArtist.image = null;
                });
            }
        };
        
        $scope.uploadCover = function (files) {
            if (files && files.length) {
                var file = files[0];
                Upload.upload({
                    url: 'api/user/uploadArtistCover',
                    fields: {},
                    headers: {'Content-Type': file.type},
                    method: 'POST',
                    file: file
                }).success(function (data, status, headers, config) {
                    $scope.newArtist.cover = data.filename;
                    $scope.coverImg = 'background-image: url(/artisti/covers/temp/' + data.filename + ')';
                }).error(function (data, status, headers, config) {
                    $scope.newArtist.cover = null;
                });
            }
        };
    }
]);