versuriNoiApp.controller('UserController', ['$scope', 'User', '$localStorage', '$location', 'Upload',
  function ($scope, User, $localStorage, $location, Upload) {
    $scope.login = function () {
        var user = new User({
            email: this.email,
            password: this.password
        });
        user.$login(function (user) {
            $localStorage.token = user.token;
            $scope.getAuthenticatedUser(user);
            $location.path('/cont');
        }, function (err) {
            $location.path('/');
        });
    };

    $scope.create = function () {
        if ($scope.newAccountForm.$valid) {
            var user = new User({
                username: this.username,
                password: this.password,
                email: this.email,
                key: this.key
            });
            user.$save(function (user) {
                $localStorage.token = user.token;
                $scope.getAuthenticatedUser(user);
                $location.path('/cont');
            }, function (err) {
                $location.path('/');
            });
        }
    };
    
    $scope.upload = function (files) {
        if (files && files.length) {
            var file = files[0];
            Upload.upload({
                url: 'api/user/uploadAvatar',
                fields: {},
                headers: {'Content-Type': file.type},
                method: 'POST',
                file: file
            }).success(function (data, status, headers, config) {
                $scope.key = data.filename;
            }).error(function (data, status, headers, config) {
                $scope.userDetails.files = null;
            });
        }
    };
    
    $scope.resetPasswordRequest = function () {
        if ($scope.resetPasswordRequestForm.$valid) {
            var user = new User({
                email: this.email
            });
            $scope.message = User.resetPasswordRequest(user);
        }
    };
    
    $scope.resetPassword = function () {   
        if ($scope.resetPasswordForm.$valid) {
            var splitPath = $location.path().split('/');
            $scope.token = splitPath[splitPath.length - 1];
            
            var user = new User({
                token: this.token,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation
            });
            
            User.resetPassword(user, function(data) {
                if(typeof data.success !== 'undefined')
                {
                    $location.path('users/login');
                }
                else
                {
                    $scope.message = data;
                }
            }); 
        }
    };

    $scope.getUserDetails = function () {   
        $scope.userDetails = User.userDetails();
    }
    
    $scope.edit = function () {
        var user = new User({
            username: this.userDetails.username,
            password: this.userDetails.password,
            passwordConfirmation: this.userDetails.passwordConfirmation,
            email: this.userDetails.account_email,
            key: this.key
        });
        
        User.edit(user).$promise.then(function (response) {  
            $localStorage.token = response.user.token;
            $scope.getAuthenticatedUser(response.user);
            
            $scope.success = response.success;
            
            $scope.userDetails = User.userDetails();
        }, function (err) {
            $scope.error = err.error;
        });
    }

    $scope.getTopUsers = function () {   
        $scope.topUsers = User.getTopUsers();
    };
  }
]);

versuriNoiApp.controller('UserAccountController', ['$scope', 'userDetails',
    function ($scope, userDetails) {
        $scope.user = userDetails;
    }
]);

versuriNoiApp.controller('UserDetailsController', ['$scope', '$rootScope', 'userDetails',
    function ($scope, $rootScope, userDetails) {
        $scope.user = userDetails;
        
        $rootScope.title = 'Profilul utilizatorului ' + userDetails.username;
    }
]);