versuriNoiApp.factory('User', ['$resource',
  function ($resource) {
    return $resource('/api/user/:userId', {
      userId: '@id'
    }, {
        userDetails: {
          method: 'GET',
          url: '/api/user/edit'
        },
        edit: {
          method: 'PUT',
          url: '/api/user/edit'
        },
        login: {
          method: 'POST',
          url: '/api/user/login'
        },
        resetPasswordRequest: {
          method: 'POST',
          url: '/api/user/resetPasswordRequest'
        },
        resetPassword: {
          method: 'POST',
          url: '/api/user/resetPassword'
        },
        getByToken: {
          method: 'GET',
          url: '/api/user/getByToken'
        },
        getTopUsers: {
          method: 'GET',
          cache: true,
          isArray: true,
          url: '/api/user/getTopUsers'
        }
    });
  }
]);