versuriNoiApp.controller('SearchController', ['$scope', '$location',
    function ($scope, $location) {

        $scope.search = function() {
            var s = $scope.s;
            
            search_string=s.replace(/-/g, "_");
            search_string=search_string.replace(/[^A-Za-z0-9_.]/g, "-");

            $location.path('/cautare/' + search_string);
        };
    }
]);

versuriNoiApp.controller('SearchResultsController', ['$rootScope', '$scope', 'searchResults',
    function ($rootScope, $scope, searchResults) {

       $scope.searchResults = searchResults;
       $scope.s = searchResults.searchString;

       $rootScope.title = searchResults.searchString;
    }
]);