versuriNoiApp.controller('LyricListController', ['$scope', 'LyricList',
    function ($scope, LyricList) {

        $scope.getUserLists = function() {            
            var list = new LyricList({
                lyric_id: $scope.lyricDetails.id
            });
            
            LyricList.query(list).$promise.
                then(function(response) {
                    if(response)
                    {
                        $scope.showLists = true;
                        $scope.userLists = response;
                    }
                }, function() { 
                    $scope.showLoginMessage = true;
                });
        }

        $scope.createList = function() {
            var list = new LyricList({
                list_name: $scope.listName,
                lyric_id: $scope.lyricDetails.id
            });
            
            LyricList.save(list).$promise.
                then(function(res) {
                    if(typeof res.newListId !== 'undefined')
                    {
                        $scope.userLists.push({
                            id: res.newListId,
                            name: $scope.listName,
                            checked: true
                        });

                        $scope.showForm = false;
                    }
                }, function() { 
                    $scope.showLoginMessage = true;
                });   
        }
        
        $scope.addLyricToList = function(list_id) {
            var list = new LyricList({
                list_id: list_id,
                lyric_id: $scope.lyricDetails.id
            });
            
            LyricList.addLyricToList(list).$promise.
                then(function(response) {
                    
                }, function(response) { 
                    alert(response.error);
                });    
        }
    }
]);

versuriNoiApp.controller('LyricListDetailsController', ['$scope', '$location', 'LyricList', 'listDetails',
    function ($scope, $location, LyricList, listDetails) {
        $scope.listDetails = listDetails;

        $scope.delete = function() {
            var list = new LyricList({
                id: listDetails.id
            });
            LyricList.delete(list).$promise.
                then(function(response) {
                    $scope.success = response.success;
                    $location.path('/cont');
                }, function(response) { 
                    $scope.error = response.error;
                });  
        }
        
        $scope.edit = function() {
            var list = new LyricList({
                id: listDetails.id,
                name: listDetails.name
            });
        
            LyricList.edit(list).$promise.
                then(function(response) {
                    $scope.editing = false;
                }, function(response) { 
                    $scope.editing = false;
                });  
        }
    }
]);