versuriNoiApp.factory('Lyric', ['$resource',
  function ($resource) {
    return $resource('/api/lyrics/:lyricId', {
      lyricId: '@lyricId'
    }, {
      update: {
        method: 'PUT'
      },
      show: {
        method: 'GET',
        url: '/api/lyrics/:lyricSlug',
        params: {
          lyricSlug: '@lyricSlug'
        }
      },
      getTopLyrics: {
        method: 'GET',
        cache: true,
        isArray: true,
        url: '/api/lyrics/getTopLyrics'
      },
      getLatestLyrics: {
        method: 'GET',
        cache: true,
        isArray: true,
        url: '/api/lyrics/getLatestLyrics'
      },
      getComments: {
        method: 'GET',
        isArray: true,
        url: '/api/lyrics/getComments'
      },
      getLatestUserCreations: {
        method: 'GET',
        isArray: true,
        url: '/api/lyrics/getLatestUserCreations'
      },
      getMostCommentedUserCreations: {
        method: 'GET',
        isArray: true,
        url: '/api/lyrics/getMostCommentedUserCreations'
      },
      getYoutubeVideo: {
        method: 'POST',
        url: '/api/lyrics/getYoutubeVideo',
        ignoreLoadingBar: true
      },
      checkYoutubeUrl: {
        method: 'POST',
        url: '/api/lyrics/checkYoutubeUrl',
        ignoreLoadingBar: true
      }
    });
  }
]);